import React, { FC } from "react";
import { Divider, Stack, Typography } from "@mui/material";
import { theme } from "../habitat";

export const SsoOr: FC = () => {
  return (
    <Stack
      id="sso-divider"
      direction="row"
      sx={{ mt: 3, mb: 3 }}
      alignItems={"center"}
    >
      <Divider sx={{ flex: 1, mr: 2, borderColor: theme.palette.grey[400] }} />
      <Typography variant="body2" sx={{ color: theme.palette.grey[500] }}>
        Or
      </Typography>
      <Divider sx={{ flex: 1, ml: 2, borderColor: theme.palette.grey[400] }} />
    </Stack>
  );
};

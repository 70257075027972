// @ts-nocheck
import React, { FC } from "react";
import { Stack } from "@mui/material";
import { SsoButton, SsoButtonText } from "./Styled";
interface SsoProvider {
  id: number;
  providerIcon: string;
  providerName: string;
  serverUrl: string;
  key: string;
}

interface SsoButtonGroupParameters {
  companySsoProviders?: Array<any>;
  type?: string;
}

export const SsoButtonGroup: FC<SsoButtonGroupParameters> = ({
  companySsoProviders,
  type = "login",
}) => {
  return (
    <Stack id="sso-button-group">
      {companySsoProviders?.map((provider, i) => (
        <SsoButton
          key={`${i}-${provider}`}
          variant="outlined"
          sx={{ mb: "12px" }}
          size="large"
          href={`/oidc/${type}/${provider.key}`}
          disableRipple={true}
          startIcon={
            <img
              src={provider.providerIcon}
              alt="google-logo"
              style={{ width: 22, height: 22 }}
            />
          }
        >
          <SsoButtonText>Continue with {provider.providerName}</SsoButtonText>
        </SsoButton>
      ))}
    </Stack>
  );
};
